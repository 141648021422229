import { ProductPrice } from '../../../utils/productPrice';
import { formatPrice } from '../../../utils/formatPrice';
import React, { useContext } from 'react';
import FunnelContext from '../../../context/FunnelContext';

export const Savings = props => {
  const {
    hideParenthesesSavings,
    hideTabSavings,
    priceSettings,
    currentCategory,
    currentProduct,
    language,
    addon
  } = props;

  const { extraObjects } = useContext(FunnelContext);

  const savings = ProductPrice({
    type: 'savings',
    currentCategory: currentCategory,
    currentProduct: currentProduct,
    filterByTag: extraObjects?.filterProductItemsByTag || false
  });
  const _savings = parseFloat(savings) + parseFloat(addon ?? 0);
  const langTabSave = language?.tabSave ?? `save`;
  if (parseFloat(_savings)) {
    return (
      !hideTabSavings && (
        <>
          <span>
            {!hideParenthesesSavings && `(`}
            {langTabSave} {formatPrice(_savings, priceSettings)}
            {!hideParenthesesSavings && `)`}
          </span>
        </>
      )
    );
  } else {
    return ``;
  }
};

export default Savings;
