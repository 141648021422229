import * as S from './SubTabItem.styles';
import Savings from '../Savings/Savings';
import React, { useContext } from 'react';
import FunnelContext from '../../../context/FunnelContext';
import { calculateDiscount } from '../../../utils/calculateDiscount';
import { ProductPrice } from '../../../utils/productPrice';

export const SubsTabItem = props => {
  const {
    hideSubsTab,
    hideParenthesesSavings,
    tabTwoLines,
    hideTabSavings,
    priceSettings,
    initKlarna,
    hideOtpTab,
    language,
    hideSubsSavings,
    subSavingsAddon,
    blockId,
    percentageSavingsTubTitle,
    differentDiscountText,
    discountText,
    isSpanTextSame,
    style,
    discountColor,
    dotBorderColor,
    dotBackgroundColor
  } = props;

  const {
    currentCategory,
    extraObjects,
    currentTubIndex,
    currentProduct,
    setCurrentProduct,
    setExtraObjects,
    setCurrentCategory,
    pageDesign
  } = useContext(FunnelContext);

  const discountedPrice = ProductPrice({
    type: `discounted_price`,
    tub: currentTubIndex,
    currentCategory: 'subscriptions',
    currentProduct: `subscription_${currentTubIndex}`,
    filterByTag: extraObjects?.filterProductItemsByTag || false
  });
  const price = ProductPrice({
    type: `price`,
    tub: currentTubIndex,
    currentCategory: currentCategory,
    currentProduct: currentProduct,
    filterByTag: extraObjects?.filterProductItemsByTag || false
  });
  const { tabSubscribe = `Subscribe` } = language || {};

  if (hideSubsTab) {
    return ``;
  }

  return (
    <S.TabItem
      onClick={() => {
        if (typeof setCurrentCategory === 'function') {
          setCurrentCategory('subscriptions');
          setCurrentProduct(`subscription_${currentTubIndex}`);
          initKlarna('subscriptions');
          setExtraObjects(
            Object.assign(extraObjects, {
              currentCategory: 'subscriptions'
            })
          );
        }
      }}
      hideOtp={hideOtpTab}
      pageDesign={pageDesign}
      className={`sub ${
        currentCategory === `subscriptions` ? 'active' : ''
      } ${style}`}
    >
      <label htmlFor={`${blockId}_sub`}>
        <input
          onChange={() => {
            if (typeof setCurrentCategory === 'function') {
              setCurrentCategory('subscriptions');
              setCurrentProduct(`subscription_${currentTubIndex}`);
              initKlarna('subscriptions');
              setExtraObjects(
                Object.assign(extraObjects, {
                  currentCategory
                })
              );
            }
          }}
          id={`${blockId}_sub`}
          name={`${blockId}_tab`}
          type="radio"
          checked={currentCategory === `subscriptions` ? 'checked' : ''}
        />
        <S.TabItemDot
          pageDesign={pageDesign}
          className={`${
            currentCategory === `subscriptions` ? 'active' : ''
          } ${style}`}
          discountColor={discountColor}
          dotBorderColor={dotBorderColor}
          dotBackgroundColor={dotBackgroundColor}
        ></S.TabItemDot>
        <S.TabItemTitle
          pageDesign={pageDesign}
          className={`${style} ${tabTwoLines ? 'two-lines' : ''}`}
          isSpanTextSame={isSpanTextSame}
          discountColor={discountColor}
        >
          <p>{tabSubscribe}&nbsp;</p>
          {percentageSavingsTubTitle && (
            <S.DiscountPercentage
              discountColor={discountColor}
              isSpanTextSame={isSpanTextSame}
            >
              {differentDiscountText ? (
                <span>{`${discountText} ${calculateDiscount(
                  price,
                  discountedPrice
                )}%`}</span>
              ) : (
                <span>{`${calculateDiscount(
                  price,
                  discountedPrice
                )}% Off`}</span>
              )}
            </S.DiscountPercentage>
          )}
          {!hideSubsSavings && (
            <Savings
              currentCategory="subscriptions"
              currentProduct={`subscription_${currentTubIndex}`}
              addon={subSavingsAddon}
              hideParenthesesSavings={hideParenthesesSavings}
              hideTabSavings={hideTabSavings}
              priceSettings={priceSettings}
              language={language}
            />
          )}
        </S.TabItemTitle>
      </label>
    </S.TabItem>
  );
};

export default SubsTabItem;
