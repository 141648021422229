import styled from 'styled-components';

export const TabItemDot = styled.div`
  border: 1px solid #ccd2e3;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background-color: #fff;

  &.active {
    border: ${({ dotBorderColor = `#0573fb` }) =>
      `1px solid ${dotBorderColor}`};
  }

  &.active:after {
    width: 9px;
    height: 9px;
    display: block;
    background-color: ${({ dotBackgroundColor = `#0573fb` }) =>
      `${dotBackgroundColor}`};
    content: ' ';
    border-radius: 50%;
    top: 2px;
    left: 2px;
  }

  &.big-font-title {
    width: 16px;
    height: 16px;

    &.active {
      border: 1px solid #99a4c7;
    }

    &.active:after {
      width: 8px;
      height: 8px;
      display: block;
      background-color: ${({ discountColor }) =>
        discountColor ? `${discountColor}` : '#bc2e3e'};
      content: ' ';
    }
  }

  ${({
    pageDesign,
    dotBorderColor = `#001C72`,
    dotBackgroundColor = `#001C72`
  }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                width: 20px;
                height: 20px;
                &.active {
                  border: 1px solid ${dotBorderColor};
                  }
                &.active:after {
                    background-color: ${dotBackgroundColor};
                    width: 12px;
                    height: 12px;
                }
                @media (max-width: 375px) {
                    flex: none;
                    width: 16px;
                    height: 16px;
                    &.active:after {
                        width: 10px;
                        height: 10px;
                    }
                }
            `;
      default:
        return ``;
    }
  }};
`;

export const DiscountPercentage = styled.p`
  color: ${({ discountColor }) =>
    discountColor ? `${discountColor}` : '#bc2e3e'};
  display: flex;
  margin: 0;
  white-space: nowrap;
  font-weight: normal;
  & > span {
    font-weight: ${({ isSpanTextSame }) => (isSpanTextSame ? 700 : 'inherit')};
`;

export const TabItem = styled.div`
  display: flex;
  position: relative;

  & label {
    cursor: pointer;
    display: flex;
    gap: 8px;
    & > input[type='radio'] {
      margin: 0;
      appearance: none;
      display: none;
    }
  }

  &.active {
    background: #fff;
  }

  ${({ hideSubs, hideOtp }) => {
    let styles = `
        background: #f4f4f4;
        flex: 0 0 50%;
        `;
    if (hideSubs || hideOtp) {
      styles = `
                flex: 0 0 100%;
                background: #FFF;
                & > label > input {
                    display: none;
                }
            `;
    }
    return styles;
  }}
  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
              background: #E5E8F1 0% 0% no-repeat padding-box;
              & label {
                  position: relative;
                  align-items: center;
                  padding: 16px 15px 15px;
                  font-size: 16px;

                  @media (max-width: 425px) {
                      padding: 12px;
                  }
  
                  @media (max-width: 375px) {
                       padding: 10px;
                  }
              }      
                `;
      default:
        return ``;
    }
  }}
  &.big-font-title {
    flex: 0 0 55%;

    & label {
      padding: 15px 8px;
      @media (max-width: 400px) {
        padding: 12px 8px;
      }
    }

    @media (max-width: 1100px) {
      flex: 0 0 60%;
    }

    @media (max-width: 550px) {
      flex: 0 0 60%;
    }

    @media (max-width: 345px) {
      flex: 0 0 60%;
    }
  }
`;

export const TabItemTitle = styled.div`
  font-size: 17px;
  color: #001c72;
  line-height: 21px;
  display: flex;
  align-items: center;
  margin-top: 1px;

  p {
    margin: 0;
  }

  & > span {
    font-size: ${({ isSpanTextSame }) => (isSpanTextSame ? 'inherit' : '16px')};
    color: ${({ discountColor = '#e26447' }) => `${discountColor}`};
    white-space: nowrap;
    line-height: 100%;

    ${({ pageDesign, discountColor = '#bc2e3e', isSpanTextSame }) => {
      switch (pageDesign) {
        case 'design2':
          return `
                    color: ${discountColor};
                    font-size: ${isSpanTextSame ? 'inherit' : '14px'};
                  `;
        default:
          return ``;
      }
    }}
  }

  @media (max-width: 550px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    font-size: 16px;
  }

  @media (max-width: 410px) {
    font-size: 14px;
  }

  @media (max-width: 375px) {
    font-size: 12px;
    gap: 2px;

    & > span {
      font-size: 12px;
      letter-spacing: 0;
    }
  }

  &.two-lines {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  &.big-font-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 100%;
    width: calc(100% - 24px);

    @media (max-width: 1100px) {
      padding-top: 3px;
      margin-top: 0;
    }

    @media (max-width: 550px) {
      font-size: 16px;
      line-height: 18px;
      flex-direction: column;
      align-items: flex-start;
    }
    @media (max-width: 370px) {
      font-size: 14px;
    }
  }
`;
